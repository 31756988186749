/**
 * @file Wrapper module in AngularJS for routing to tabletenrollment page
 * @author Max van Loosbroek
 */

import './TabletEnrollment/TabletEnrollment.module'
import StoreService from '../../common/redux/store.service'
import tabletEnrollmentPage from './tabletEnrollmentPage.component'

export default angular
  .module('components.tabletEnrollmentPage', ['components.tb.tabletEnrollmentReact'])
  .component('tabletEnrollmentPage', tabletEnrollmentPage)
  .service('StoreService', StoreService)
  .config($stateProvider => {
    $stateProvider.state('tabletEnrollment', {
      parent: 'app',
      url: `^/tablet-enrollment/`,
      component: 'tabletEnrollmentPage',
      reloadOnSearch: false,
      params: {},
      resolve: {}
    })
  }).name
