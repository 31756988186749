import React, { useCallback, useEffect } from 'react';
import { TbDashTable } from 'tinybots-react-components';
import { useTabletEnrollmentValueMapper } from '../../helpers/tableMappers';
import { TabletEnrollmentProps } from '../TabletEnrollment';
import { EmptyDeviceList } from './EmptyDeviceList';
import i18next from 'i18next';
import { fetchDevices } from '../../redux/reducer';

export const EnrollmentTable = ({
    rows,
    columns,
    t,
    loading,
    dispatch,
}: Pick<TabletEnrollmentProps, 'rows' | 'columns' | 'loading' | 'dispatch'> & { t: typeof i18next.t }) => {
    const valueMapper = useTabletEnrollmentValueMapper();
    const voidCallback = useCallback(() => {}, []);
    const getDevices = useCallback(({ updateOnly }: { updateOnly?: boolean }) => dispatch(fetchDevices({ updateOnly })), []);

    useEffect(() => {
        // Fetch all devices initially
        getDevices({});

        // Set up polling to fetch new devices every 5 seconds
        const interval = setInterval(() => {
            getDevices({ updateOnly: true });
        }, 5000);

        // Cleanup on component unmount
        return () => clearInterval(interval);
    }, [getDevices]);

    return (
        <>
            <TbDashTable
                loading={loading}
                color="#515151"
                params={{}}
                rows={rows}
                columns={columns.filter((c) => !c.hidden)}
                onScrollToEnd={voidCallback}
                onClickRow={voidCallback}
                valueMapper={valueMapper}
            />
            {rows.length == 0 && !loading ? <EmptyDeviceList t={t} style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, margin: 'auto' }}></EmptyDeviceList> : null}
        </>
    );
};
