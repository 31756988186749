import { DialogTitle, DialogContent } from "@material-ui/core";
import React from "react";
import { TbDashDialog } from "tinybots-react-components";
import { TbDialogProps } from "tinybots-react-components/lib/components/DashboardComponents/TbDialog";
import crossIcon from '../../../../../assets/img/icons/TB_Android_Large_Checkmark_Icon.svg';

export const PushSuccessDialog = ({ open, handleClose, t }: Pick<TbDialogProps, 'open' | 'handleClose'> & { t: (tag: string) => string, handleClose: Function }) => {
  return <TbDashDialog
    color='#333333'
    open={open}
    closeButton={t('ENROLLMENT.POPUP.SUCCESS_PUSH.BUTTON_SUBMIT')}
    handleClose={handleClose}
  >
    <DialogContent className={""}>
      <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ background: `url('${crossIcon}') no-repeat 50%`, width: 126, height: 126 }} aria-label='Item Icon' />
        <DialogTitle style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 20, color: '#5b9130' }}>
          {t('ENROLLMENT.POPUP.SUCCESS_PUSH.TITLE')}
        </DialogTitle>
        <p style={{ textAlign: 'center', fontFamily: 'MontSerrat' }}>
          {t('ENROLLMENT.POPUP.SUCCESS_PUSH.MESSAGE')}
        </p>
      </section>
    </DialogContent>
  </TbDashDialog>
}
