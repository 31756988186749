/**
 * @file Wrapper component in AngularJS for React tablet enrollment page
 * @author Max van Loosbroek
 */

import template from './tabletEnrollmentPage.html'

const tabletEnrollmentPage = {
  bindings: {
    user: '<'
  },
  template,
  controller: /* @ngInject */ class TabletEnrollmentPageController {
    constructor (StoreService, $state, $location) {
      this.StoreService = StoreService
      this.$state = $state
      this.$location = $location
      this.urlParams = this.$state.params
    }
  }
}

export default tabletEnrollmentPage
