const en = {
    MFA: {
        SUBMIT: 'Next',
        STATE_TITLE_ON: 'Set up 2FA',
        STATE_TITLE_OFF: 'Disable 2FA',
        INFO_LINK: 'What is 2-factor authentication',
        INFO_CONTENT:
            '2-factor authentication is an additional security measure to secure your user account. When logging in, you will be asked for a code in addition to your password before entering the app. This code is renewed every 30s, and only you will receive this new code.',
        WARN: {
            TITLE: 'Turn on 2FA',
            MESSAGE: '2-factor authentication is required, continue to set it up.'
        },
        PASSWORD_POPUP: {
            TITLE: 'Enable 2FA',
            TITLE_DISABLE: 'Disable 2FA',
            MESSAGE: 'You need your password to change your user settings.',
            BUTTON_NEXT: 'Continue',
            BUTTON_PREV: 'Back'
        },
        OTP_POPUP: {
            TITLE: 'Enable 2FA',
            TITLE_DISABLE: 'Disable 2FA',
            LABEL: '2FA 6-digit code',
            MESSAGE: 'You must enter your 2FA 6-digit code to change your user settings.',
            BUTTON_NEXT: 'Continue',
            BUTTON_PREV: 'Back'
        },
        STEPS: {
            STEP1: {
                TITLE: 'Install an authenticator app',
                ACTION: 'Install',
                SEPARATOR: 'or',
                OPTION1: 'Microsoft authenticator',
                LINK1: 'https://www.microsoft.com/en-us/account/authenticator',
                OPTION2: 'Authy',
                LINK2: 'https://authy.com/download/'
            },
            STEP2: {
                TITLE: 'Scan the QR code with your authenticator app or enter the ‘secret code’.',
                QR: 'QR code',
                SECRET: 'Secret code'
            },
            STEP3: {
                TITLE: 'Check whether your 2-factor authentication is set up correctly.',
                CONTENT: 'Enter the 6-digit code from your authenticator app: '
            },
            STEP4: {
                TITLE: 'Your password for confirmation.',
                CONTENT: 'Fill in your password: '
            }
        }
    },
    POPUP: {
        ERROR: {
            BUTTON_SUBMIT: 'Continue',
            TITLE: 'Oops! Something went wrong.',
            MESSAGE: 'Check your internet connection or contact [help@tinybots.nl](mailto:help@tinybots.nl)'
        }
    },
    AC: {
        MFA: {
            TITLE: 'You have 2FA enabled. Enter your 2FA code from your authenticator: ',
            LABEL_OTP: '2FA 6-digit code',
            ERROR: 'This code is no longer valid. Check if you have entered a valid and recent code',
            ERROR_INCOMPLETE: 'This code is not valid. Enter a code of 6 digits',
            ERROR_PW: 'Enter a valid pasword',
            ERROR_PW_EMAIL: 'The email address and / or password is incorrect'
        }
    },
    OWNERS: {
        DELETE: 'Account verwijderen'
    },
    ENROLLMENT: {
        EMPTY_MESSAGE: "Scan the QR-code to register devices",
        QR_TEXT: "Register device",
        PUSH_BUTTON: "PUSH LIST",
        POPUP: {
            CONFIRM_PUSH: {
                BUTTON_SUBMIT: 'CONFIRM PUSH',
                BUTTON_CANCEL: 'CANCEL',
                TITLE: 'Check and confirm your push',
                MESSAGE_1: 'Amount of devices',
                MESSAGE_2: 'Scanner(s)'
            },
            ERROR_RESTRICTED: {
                TITLE: 'You don’t have access',
                MESSAGE: 'Access to this screen is restricted. Please log out and log back in to continue.'
            },
            ERROR: {
                BUTTON_SUBMIT: 'Close',
                TITLE: 'Unknown error',
                MESSAGE: 'An unknown error occurred. Check your internet connection or contact [help@tinybots.nl](mailto:help@tinybots.nl)'
            },
            SUCCESS_PUSH: {
                BUTTON_SUBMIT: 'Close',
                TITLE: 'Push Successful',
                MESSAGE: 'The devices were successfully pushed to admin dashboard.'
            },
            ERROR_PUSH: {
                BUTTON_SUBMIT: 'Close',
                TITLE: 'Push Failed',
                MESSAGE: 'The devices could not be added to the admin dashboard. Please try again.'
            }
        },
    }
}
export default en
