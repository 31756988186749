
import { createStyles, createTheme, makeStyles, MuiThemeProvider } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import styledComponentsTheme from '../../../common/react/theme/styledComponentsTheme'
import StoreService from '../../../common/redux/store.service'
import { RootState } from '../../../common/redux/root.reducer'
import { clearError, clearPushError, clearSuccess, EnrollmentState, pushDevices, selectQRDetail, selectRows } from '../redux/reducer'
import { TabletEnrollmentRow } from '../types/TabletEnrollmentRow'
import { TabletEnrollmentColumn } from '../types/TabletEnrollmentColumn'
import { AppDispatch } from '../../../common/redux/store'
import { TbDashErrorDialog } from 'tinybots-react-components'
import { EnrollmentQR } from '../types/EnrollmentQR'
import { useTranslation } from 'react-i18next'
import { PushErrorDialog } from './dialogs/PushErrorDialog'
import { PushSuccessDialog } from './dialogs/PushSuccessDialog'
import { ConfirmPushDialog } from './dialogs/ConfirmPushDialog'
import { EnrollmentTable } from './devices-overview/EnrollmentTable'
import { EnrollmentSection } from './enrollment-section/EnrollmentSection'

const localTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#ba9a1b'
    }
  }
})

export interface TabletEnrollmentProps {
  $http: any
  dispatch: AppDispatch
  StoreService: StoreService
  rows: TabletEnrollmentRow[]
  columns: TabletEnrollmentColumn[]
  lastFetchedTimestamp?: number
  errorMessage?: string
  pushErrorMessage?: string
  errorMessageQR?: string
  qrDetail: EnrollmentQR
  loading: boolean
  pushSuccess: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%'
    }
  })
)
export const TabletEnrollment = ({ rows, columns, errorMessage, errorMessageQR, pushErrorMessage, pushSuccess, qrDetail, dispatch, loading }: Omit<TabletEnrollmentProps, '$http' | 'StoreService'>) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [showPushWarning, setShowPushWarning] = useState(false)
  const clearErrors = useCallback(() => {
    dispatch(clearError())
  }, [])
  const pushList = useCallback(() => dispatch(pushDevices({ deviceIds: rows.map(row => row.id) })), [rows]);
  const generalError = errorMessage || errorMessageQR
  const accessRestrictedError = generalError?.includes('status: 403')
  return <div className={classes.root}>
    <EnrollmentSection dispatch={dispatch} qrDetail={qrDetail} t={t} onSubmit={() => setShowPushWarning(true)} pushEnabled={rows.length > 0} />
      <section aria-label='Devices' style={{ position: 'relative', flexGrow: 1, display: 'flex' }}>
        <EnrollmentTable columns={columns} loading={loading} rows={rows} t={t} dispatch={dispatch} />
      </section>
    <TbDashErrorDialog
      title={accessRestrictedError ? t('ENROLLMENT.POPUP.ERROR_RESTRICTED.TITLE') : undefined}
      message={accessRestrictedError ? t('ENROLLMENT.POPUP.ERROR_RESTRICTED.MESSAGE') : generalError}
      open={!!generalError}
      handleClose={clearErrors}
    />
    <ConfirmPushDialog
      count={rows.length}
      userNames={rows.map(row => row.userName)}
      open={showPushWarning}
      handleClose={() => setShowPushWarning(false)}
      handleSubmit={() => {
        setShowPushWarning(false)
        pushList()
      }}
      t={t}
    />
    <PushErrorDialog handleClose={() => {
      dispatch(clearPushError())
    }} open={!!pushErrorMessage} message={pushErrorMessage} t={t} />
    <PushSuccessDialog open={pushSuccess} handleClose={() => {
      dispatch(clearSuccess())
    }} t={t} />
  </div>
}

const ConnectedTabletEnrollment = (
  props: Pick<TabletEnrollmentProps, '$http' | 'StoreService'>
) => {
  const tabletDevicesState = useSelector<RootState, EnrollmentState>(
    ({ tabletDevices }) => tabletDevices
  )
  const tabletDevices = useSelector(selectRows)
  const qr = useSelector(selectQRDetail)

  const { qrError, error, pushError, columns, lastFetchedTimestamp, loading, pushSuccess } = tabletDevicesState

  const dispatch = useDispatch<AppDispatch>()
  return (
    <TabletEnrollment
      {...props}
      lastFetchedTimestamp={lastFetchedTimestamp}
      qrDetail={qr}
      rows={tabletDevices}
      columns={columns}
      pushErrorMessage={pushError}
      errorMessage={error}
      errorMessageQR={qrError}
      pushSuccess={pushSuccess}
      loading={loading && tabletDevices.length < 1}
      dispatch={dispatch}
    />
  )
}

const TabletEnrollmentWrapper: React.FunctionComponent<Pick<
  TabletEnrollmentProps,
  '$http' | 'StoreService'
>> = (props: Pick<TabletEnrollmentProps, '$http' | 'StoreService'>) => {
  return (
    <MuiThemeProvider theme={localTheme}>
      <ThemeProvider theme={styledComponentsTheme}>
        <Provider store={props.StoreService.store}>
          <ConnectedTabletEnrollment {...props} />
        </Provider>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

export default TabletEnrollmentWrapper
