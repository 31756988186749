import { DialogTitle, DialogContent } from "@material-ui/core";
import React from "react";
import { TbDashDialog } from "tinybots-react-components";
import { TbDialogProps } from "tinybots-react-components/lib/components/DashboardComponents/TbDialog";
import crossIcon from '../../../../../assets/img/icons/TB_Android_Large_Cross_Icon.svg';

export const PushErrorDialog = ({ open, handleClose, t, message }: Pick<TbDialogProps, 'open' | 'handleClose'> & { t: (tag: string) => string, message: string }) => <TbDashDialog
  color='#333333'
  open={open}
  closeButton={t('ENROLLMENT.POPUP.ERROR_PUSH.BUTTON_SUBMIT')}
  handleClose={handleClose}
>
  <DialogContent className={""}>
    <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ background: `url('${crossIcon}') no-repeat 50%`, width: 126, height: 126 }} aria-label='Item Icon' />
      <DialogTitle style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 20, color: 'rgb(218, 71, 37)' }}>
        {t('ENROLLMENT.POPUP.ERROR_PUSH.TITLE')}
      </DialogTitle>
      <p style={{ textAlign: 'center', fontFamily: 'MontSerrat' }}>{t('ENROLLMENT.POPUP.ERROR_PUSH.MESSAGE')}</p>
      <p style={{ textAlign: 'center', fontFamily: 'MontSerrat' }}><i>{message}</i></p>
    </section>
  </DialogContent>
</TbDashDialog>
