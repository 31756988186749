import { useEffect, useRef, useState } from "react"
import { TbDymo } from "../../../tools/TbDymo/TbDymo"

export const useDymo = () => {
  const isMounted = useRef(false);
  
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  useEffect(() => {
    const dymo = new TbDymo()
    setTbDymo(dymo)
    dymo.connect()
    dymo.isConnected().then(connected => {
      if(isMounted.current) {
        setDymoConnected(connected)
      }
    })
  }, [])

  const [ dymoConnected, setDymoConnected ] = useState(false)
  const [ tbDymo, setTbDymo ] = useState<TbDymo>()

  return {
    dymoConnected,
    tbDymo
  }
}