import './auth/auth.module'
import './error/error.module'
import { organisationsModule } from './organisations/organisations.module'
import { heartbeatModule } from './heartbeat/heartbeat.module'
import { overviewPageModule } from './overview/overviewPage.module'
import { orderOverviewPageModule } from './orders/orderOverviewPage.module'
import { tabletEnrollmentPage } from './tablet-enrollment/tabletEnrollmentPage.module'
import { dashtransferPageModule } from './dashtransfer/dashtransferPage.module'
import './analytics/CustomQuery/customQueryPage.module'

export default angular.module('components', [
    'components.auth',
    'components.error',
    'components.heartbeat',
    'components.overviewPage',
    'components.orderOverviewPage',
    'components.dashtransferPage',
    'components.organisations',
    'components.customQueryPage',
    'components.tabletEnrollmentPage',
]).name
