import React from 'react';
import { TbTableRowData } from 'tinybots-react-components';
import { TabletEnrollmentRow } from '../types/TabletEnrollmentRow';
import { ReactElement } from 'react';
import moment from 'moment';
import { PrintButton } from '../TabletEnrollment/PrintButton';
import { useDymo } from '../../../common/hooks/useDymo';

export const useTabletEnrollmentValueMapper = () => {
    const { dymoConnected, tbDymo } = useDymo();

    return ({ row, valueKey }: { row: TbTableRowData; valueKey: string }) => {
        const { serial, id, hardwareVersion } = row as TabletEnrollmentRow;

        const inputValue = row[valueKey as keyof TabletEnrollmentRow];
        const stringOrNumber = typeof inputValue === 'string' || typeof inputValue === 'number';
        let value: ReactElement | string | number;
        let tooltipValue: ReactElement | string | number;
        let tooltip = false;
        const color = 'black';
        const style = {
            color: value === 'N/A' ? 'rgb(163 163 163)' : color,
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            lineHeight: '36px',
            fontFamily: value === 'N/A' ? 'Roboto-Mono-Light' : 'inherit',
        };
        switch (valueKey) {
            case 'createdAt':
                value = inputValue ? moment(inputValue as string).format('hh:mm DD-MM-YYYY') : '-';
                break;
            case 'buttons':
                value = (
                    <section>
                        <PrintButton
                            tbDymo={tbDymo}
                            id={id}
                            hardwareVersion={hardwareVersion}
                            serial={serial}
                            dymoConnected={dymoConnected}
                        ></PrintButton>
                    </section>
                );
                break;
            default:
                value = stringOrNumber ? inputValue ?? '-' : '-';
                break;
        }
        value = <span style={style}>{value}</span>;
        return { value, tooltip, tooltipValue };
    };
};
