import { createSlice, createAsyncThunk, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { IHttpService } from 'angular'
import { TabletDevice } from '../types/TabletDevice';
import { EnrollmentQR } from '../types/EnrollmentQR';
import { RootState } from '../../../common/redux/root.reducer';
import { URLS } from '../../../common/utilities/constants/constants.module';
import { AppDispatch } from '../../../common/redux/store';
import { TabletEnrollmentColumn } from '../types/TabletEnrollmentColumn';
import { TabletEnrollmentRow } from '../types/TabletEnrollmentRow';

export const COLUMNS: TabletEnrollmentColumn[] = [
  {
    key: 'id',
    headerName: 'no.',
  },
  {
    key: 'createdAt',
    headerName: 'time and date',
  },
  {
    key: 'serial',
    headerName: 'serial',
  },
  {
    key: 'hardwareVersion',
    headerName: 'hardware version',
  },
  {
    key: 'embodimentVersion',
    headerName: 'embodiment version',
  },
  {
    key: 'userName',
    headerName: 'scanner name',
  },
  {
    key: 'buttons',
    headerName: '',
  }
]

interface PushDevicesPayload {
  deviceIds: number[];
}

type EnrollmentQREntity = Omit<EnrollmentQR, 'qrImages'> & { qrString: string }

const createErrorMessage = (error: any) => {
  try {
    return error.status ? `status: ${error.status}, errorMessage: "${error.statusText}"` : 'Unknown error'
  } catch (error) {
    console.error(error)
    return 'Unknown error'
  }
}

// Create an entity adapter for devices
const devicesAdapter = createEntityAdapter<TabletDevice>({
  selectId: (device) => device.id,
  sortComparer: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
});

// Define the device async thunk as before
export const fetchDevices = createAsyncThunk<TabletDevice[], { pushed?: boolean; createdSince?: number, updateOnly?: boolean }, { extra: { $http: IHttpService } }>(
  'tabletDevices/fetchDevices',
  async ({ pushed, createdSince }, { rejectWithValue, extra }) => {
    try {
      const response = await extra.$http.get<TabletDevice[]>(URLS.admin.tabletDevices, { params: { pushed, createdSince } });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(createErrorMessage(error));
    }
  }
);

// Thunk for pushing devices
export const pushDevices = createAsyncThunk<
  void,
  PushDevicesPayload,
  {
    state: RootState
    dispatch: AppDispatch
    extra: { $http: IHttpService }
    rejectValue: string
  }
>('tabletDevices/pushDevices', async ({ deviceIds }, { dispatch, extra, rejectWithValue }) => {
  try {

    // Loop over each deviceId and push it
    await Promise.all(
      deviceIds.map((deviceId) =>
        extra.$http.post(`${URLS.admin.tabletDevices}/${deviceId}/push`, {})
      )
    );

    // After all requests succeed, refresh the device list
    await dispatch(fetchDevices({ updateOnly: true }));
  } catch (error: any) {
    return rejectWithValue(createErrorMessage(error));
  }
});


// Async thunk to fetch QR details
export const fetchQRDetail = createAsyncThunk<EnrollmentQREntity, boolean | undefined, { extra: { $http: IHttpService } }>(
  'tabletDevices/fetchQRDetail',
  async (refresh = false, { rejectWithValue, extra }) => {
    try {
      let result: any
      if (refresh) {
        result = await extra.$http.post<EnrollmentQR>(URLS.admin.tabletQR, null);
      } else {
        try {
          result = await extra.$http.get<EnrollmentQR>(URLS.admin.tabletQR, null);
        } catch (error) {
          result = await extra.$http.post<EnrollmentQR>(URLS.admin.tabletQR, null);
        }
      }
      const { data: { qrContent, ...rest } } = result 
      const qrImages = qrContent;
      const qrData: EnrollmentQREntity = { ...rest, qrString: qrImages.toString('base64') };
      return qrData;
    } catch (error: any) {
      return rejectWithValue(createErrorMessage(error));
    }
  }
);

// Define the initial state using devicesAdapter
const initialState = devicesAdapter.getInitialState({
  lastFetchedTimestamp: null as number | null,
  loading: false,
  error: null as string | null,
  pushError: null as string | null, 
  qrDetail: null as EnrollmentQREntity | null,
  qrLoading: false,
  qrError: null as string | null,
  pushSuccess: false,
  columns: COLUMNS
});

export type EnrollmentState = typeof initialState

// Create the slice
const tabletDevicesSlice = createSlice({
  name: 'tabletDevices',
  initialState,
  reducers: {
    clearSuccess(state) {
      state.pushSuccess = false;
    },
    clearError(state) {
      state.error = null;
    },
    clearPushError(state) {
      state.pushError = null;
    },
    clearQRError(state) {
      state.qrError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Devices fetching reducers
      .addCase(fetchDevices.pending, (state, action) => {
        if (!action.meta.arg.updateOnly) {
          state.loading = true;
        }
        state.error = null;
      })
      .addCase(fetchDevices.fulfilled, (state, action: PayloadAction<TabletDevice[]>) => {
        state.loading = false;
        devicesAdapter.upsertMany(state, action.payload); // Add or update devices
        state.lastFetchedTimestamp = Date.now(); // Update the last fetch timestamp
      })
      .addCase(fetchDevices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Push devices reducers
      .addCase(pushDevices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(pushDevices.fulfilled, (state) => {
        state.loading = false;
        state.pushSuccess = true;
      })
      .addCase(pushDevices.rejected, (state, action) => {
        state.loading = false;
        state.pushError = action.payload as string;
      })
      // QR detail fetching reducers
      .addCase(fetchQRDetail.pending, (state) => {
        state.qrLoading = true;
        state.qrError = null;
      })
      .addCase(fetchQRDetail.fulfilled, (state, action: PayloadAction<EnrollmentQREntity>) => {
        state.qrLoading = false;
        state.qrDetail = { ...action.payload };
      })
      .addCase(fetchQRDetail.rejected, (state, action) => {
        state.qrLoading = false;
        state.qrError = action.payload as string;
      });
  },
});

export const createDeviceRows = (devices: TabletDevice[]): TabletEnrollmentRow[] =>
  devices.map(
    ({
      id,
      createdAt,
      serial,
      hardwareVersion,
      embodimentVersion,
      userId,
      userName,
    }) => ({
      key: serial,
      id,
      createdAt,
      serial,
      hardwareVersion,
      embodimentVersion,
      userId,
      userName,
    })
  )


// Export the adapter selectors
export const {
  selectAll: selectAllDevices,
  selectById: selectDeviceById,
  selectIds: selectDeviceIds,
} = devicesAdapter.getSelectors((state: RootState) => state.tabletDevices);
export const pendingDevices = createSelector(
  selectAllDevices,
  (devices) => devices.filter(device => device.dashboardRobotId == undefined || device.dashboardRobotId == null)
)
export const selectRows = createSelector(
  pendingDevices,
  createDeviceRows
)


export const selectQRDetail = createSelector(
  [
    (state: RootState) => state.tabletDevices.qrDetail, // Select the QR detail
    selectAllDevices, // Select all devices
  ],
  (qrDetail, devices): EnrollmentQR | null => {
    if (!qrDetail) return null;

    const { qrString, ...rest } = qrDetail;

    // Check if any device has an enrollmentId matching qrDetail.id
    const hasMatchingDevice = devices.some(device => device.enrollmentId === qrDetail.id);

    return {
      ...rest,
      qrContent: Buffer.from(qrString, 'base64'), // Deserialize the base64 string to Buffer
      expired: hasMatchingDevice, // Add the expired property based on matching device
    };
  }
);

// Export actions and reducer
export const { clearError, clearQRError, clearPushError, clearSuccess } = tabletDevicesSlice.actions;
export const tabletDevicesReducer = tabletDevicesSlice.reducer;
