import { IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import React, { useCallback, useEffect, useState } from 'react';
import { TbDymo } from '../../../../tools/TbDymo/TbDymo';
import styledComponentsTheme from '../../../common/react/theme/styledComponentsTheme';

export const PrintButton = ({
    dymoConnected,
    tbDymo,
    hardwareVersion,
    id,
    serial,
    ...props
}: {
    dymoConnected: boolean;
    serial: string;
    tbDymo: TbDymo;
    hardwareVersion: string;
    id: number;
}) => {
    const [printed, setPrinted] = useState(false);

    useEffect(() => {
        const printed = localStorage.getItem(`tabletEnrollment:${serial}:printed`);
        setPrinted(printed === 'true');
    }, []);

    const dymoPrint = useCallback(async () => {
        await tbDymo.printSerialV2(serial);
        await tbDymo.printBox(`${hardwareVersion}-${id}`);
        localStorage.setItem(`tabletEnrollment:${serial}:printed`, 'true');
        setPrinted(true);
    }, [id, serial, hardwareVersion]);
    return (
        <IconButton
            {...props}
            disabled={!dymoConnected}
            style={{ opacity: dymoConnected ? 1 : 0.2, color: printed ? styledComponentsTheme.colors.darkgreen : '' }}
            onClick={dymoConnected ? dymoPrint : null}
        >
            <PrintIcon />
        </IconButton>
    );
};
