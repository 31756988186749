import { createStyles, makeStyles } from "@material-ui/styles"
import React from "react"
import { AppDispatch } from "../../../../common/redux/store"
import { EnrollmentQR } from "../../types/EnrollmentQR"
import { QRImage } from "./QRImage"
import { Button } from "tinybots-react-components"
import i18next from "i18next"

const useStyles = makeStyles(() =>
  createStyles({
    register: {
      position: 'relative',
      minWidth: 390,
      background: '#ffffff',
      padding: '58px 42px 58px 42px',
      alignContent: 'space-between',
      flexDirection: 'column',
      display: 'flex'
    },
    header: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: 40.5,
      right: -1,
      background: 'rgb(81, 81, 81)',
      zIndex: 1
    }
  })
)

export const EnrollmentSection = ({ dispatch, qrDetail, t, onSubmit, pushEnabled }: { dispatch: AppDispatch, qrDetail: EnrollmentQR, t: typeof i18next.t, onSubmit: React.MouseEventHandler<HTMLButtonElement>, pushEnabled: boolean }) => {
  const classes = useStyles()

  return <section className={classes.register}>
    <div className={classes.header}></div>
    <section>
      <h2 style={{
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: 16
      }}>{t('ENROLLMENT.QR_TEXT')}</h2>
      <QRImage qrDetail={qrDetail} dispatch={dispatch} />
    </section>
    <Button aria-label='Push Devices' disabled={!pushEnabled} style={{ background: pushEnabled ? '#8356ac' : ' #e8e8e8', marginTop: 'auto', cursor: pushEnabled ? 'pointer' : 'default', border: 'none', color: 'white' }} onClick={onSubmit}>{t('ENROLLMENT.PUSH_BUTTON')}</Button>
  </section>
}