/**
 * @file Wrapper module to load react tabletEnrollment component inside angular
 * @author Max van Loosbroek
 */

import angular from 'angular'
import StoreService from '../../../common/redux/store.service'
import TabletEnrollment from './TabletEnrollment'

export default angular
  .module('components.tb.tabletEnrollmentReact', ['react'])
  .directive(
    'tbTabletEnrollmentReact',
    (reactDirective, StoreService: StoreService) =>
      reactDirective(TabletEnrollment, [], {}, { StoreService })
  )
