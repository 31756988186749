import { DialogTitle, DialogContent } from "@material-ui/core";
import React from "react";
import { TbDashDialog } from "tinybots-react-components";
import { TbDialogProps } from "tinybots-react-components/lib/components/DashboardComponents/TbDialog";

export const ConfirmPushDialog = ({ open, handleClose, handleSubmit, userNames, count, t }: Pick<TbDialogProps, 'open' | 'handleClose' | 'handleSubmit'> & { count: number, userNames: string[],
   t: (tag: string) => string }) => <TbDashDialog
  color='#639bb1'
  open={open}
  closeButton={t('ENROLLMENT.POPUP.CONFIRM_PUSH.BUTTON_CANCEL')}
  handleClose={handleClose}
  submitButton={t('ENROLLMENT.POPUP.CONFIRM_PUSH.BUTTON_SUBMIT')}
  handleSubmit={handleSubmit}
>
  <DialogContent className={""}>
    <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <DialogTitle style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 20, color: '#639bb1' }}>
        {t('ENROLLMENT.POPUP.CONFIRM_PUSH.TITLE')}
      </DialogTitle>
      <p style={{ textAlign: 'center', fontFamily: 'MontSerrat-Bold', marginBottom: 0 }}>{t('ENROLLMENT.POPUP.CONFIRM_PUSH.MESSAGE_1')}</p>
      <p style={{ textAlign: 'center', fontFamily: 'MontSerrat', marginTop: 5 }}>{count}</p>
      <p style={{ textAlign: 'center', fontFamily: 'MontSerrat-Bold', marginBottom: 0 }}>{t('ENROLLMENT.POPUP.CONFIRM_PUSH.MESSAGE_2')}</p>
      <p style={{ textAlign: 'center', fontFamily: 'MontSerrat', marginTop: 5 }}>{userNames.join(', ')}</p>
    </section>
  </DialogContent>
</TbDashDialog>
