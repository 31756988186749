import React, { useCallback, useEffect, useState } from 'react'
import { EnrollmentQR } from '../../types/EnrollmentQR'
import RefreshIcon from '@material-ui/icons/Refresh';
import { AppDispatch } from '../../../../common/redux/store';
import { fetchQRDetail } from '../../redux/reducer';

export const QRImage = ({ qrDetail, dispatch }: { qrDetail: EnrollmentQR, dispatch: AppDispatch }) => {
  const refreshQR = useCallback(() => dispatch(fetchQRDetail(true)), []);
  const getQR = useCallback(() => dispatch(fetchQRDetail(false)), []);
  const [isExpired, setIsExpired] = useState(true);

  useEffect(() => {
    // Initial QR detail fetch
    getQR();
  }, [])

  useEffect(() => {
    // Function to calculate remaining time until expiration
    const calculateTimeUntilExpiry = (expiry: string): number => {
      const expiryDate = new Date(expiry).getTime();
      const currentDate = new Date();
      var currentDateUtc = Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(),
      currentDate.getUTCDate(), currentDate.getUTCHours(),
      currentDate.getUTCMinutes(), currentDate.getUTCSeconds());
      return Math.max(expiryDate - currentDateUtc, 0);
    };

    // Refresh the QR detail when it expires
    let timer: NodeJS.Timeout | null = null;
    if (qrDetail?.expiredAt) {
      const timeUntilExpiry = calculateTimeUntilExpiry(qrDetail?.expiredAt);
      setIsExpired(timeUntilExpiry == 0)
      timer = setTimeout(() => {
        refreshQR();
      }, timeUntilExpiry);
    }

    // Cleanup timer on component unmount or when expiredAt changes
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [dispatch, qrDetail?.expiredAt]);

  const base64Image = `data:image/png;base64,${qrDetail?.qrContent.toString('base64')}`;
  return <div style={{ marginTop: 40, paddingBottom: '100%', border: 'solid #838383 1px', borderRadius: 10, position: 'relative' }}>
    {qrDetail && <img style={{ position: 'absolute', width: '100%', cursor: 'pointer', padding: 5 }} src={base64Image} onClick={refreshQR} />}
    {isExpired && (
      <div
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(219 219 219 / 0.5)', // Semi-transparent grey
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        aria-label='Refresh QR Button'
        onClick={refreshQR}
      >
        <div style={{ background: 'rgba(0 0 0 / 0.64)', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 48, height: 48 }}><RefreshIcon style={{ fontSize: 32, color: 'white' }} /></div>
      </div>
    )}
  </div>
}